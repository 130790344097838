<template>
  <div id="home" class="home">
    <div style="width: 100vw; position: relative; left: -14vw">
      <van-swipe ref="swiper" :autoplay="5000" lazy-render @change="onChange">
        <van-swipe-item
          @click="goroll(item)"
          style="width: 100vw"
          class="swiper-item"
          v-for="(item, index) in swiperList"
          :key="index"
        >
          <img :src="item.image" style="width: 100%" />
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator">
            <i
              @click="onChange1(index)"
              :class="swiperIndex === item - 1 ? 'active-swiper' : ''"
              v-for="(item, index) in swiperList.length"
              :key="index"
            ></i>
          </div>
        </template>
      </van-swipe>
    </div>
    <div class="home-box">
      <div class="typetab">
        <div
          class="tabitem tabl point"
          @click="typeclick(0)"
          :class="{ actabl: typetabindex == 0 }"
        >
          盲盒开箱
        </div>
        <div
          class="tabitem tabr point"
          @click="typeclick(1)"
          :class="{ actabr: typetabindex == 1 }"
        >
          积分玩法
        </div>
      </div>
      <div class="home-List-box" v-show="typetabindex == 0">
        <div v-for="(item, index) in HotList" :key="index + 'a'">
          <div v-if="item.box.length" class="Title" :id="item.id">
            <!-- 111 {{ item }} -->
            <img :src="item.src" class="play-title" alt="" />
            <!-- <div class="triangle"></div> -->
            <!-- <img style="width:10rem" :src="item.src" alt="" srcset=""> -->
          </div>
          <div
            v-if="item.box.length"
            class="home-List"
            :class="{ 'home-List-5': item.box.length > 4 }"
            :id="item.id"
          >
            <div
              class="home-list-item"
              v-for="(item, index) in item.box"
              :key="index"
              @click="ToOpen(item.id)"
            >
              <div class="tagbox">
                <img
                  v-if="item.tag == 2"
                  src="../../assets/images/home/tagnew.png"
                  alt=""
                />

                <img
                  v-if="item.tag == 1"
                  src="../../assets/images/home/taghot.png"
                  alt=""
                />
              </div>

              <div class="pic">
                <img v-lazy="item.cover" />
              </div>
              <div class="weapon_cover">
                <img :src="item.weapon_cover" alt="" />
              </div>
              <div class="Blind_btn">
                <!-- <p>打开箱子</p> -->
                <span>{{ item.name }}</span>
              </div>
              <!-- $更换为图标颜色统一黄色 -->
              <p
                style="
                  color: #dea61f;
                  font-size: 0.23rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <Cprice :price="item.bean" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <pointbox v-show="typetabindex == 1"></pointbox>
    </div>
    <div
      class="uav"
      v-show="typetabindex == 0"
      :class="{ hidden: !isVisible, visible: isVisible }"
    >
      <ul id="toc">
        <li
          v-for="(item, index) in HotList"
          :key="index"
          :class="{ active: activeSectionId == item.id }"
          class="point tocbox"
        >
          <a @click.prevent="scrollToSection(item.id)"> {{ item.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { GetHotBox, Rooms, Info } from "@/network/api.js";
import pointbox from "./pointbox.vue";
export default {
  name: "Home",
  data() {
    return {
      HotList: [],
      BladeList: [],
      recruitList: [],
      classicList: [],
      vitalityList: [],
      hotboxlist: [],
      time: 10000,
      Headlines: [],
      soulList: [],
      XyList: [],
      swiperIndex: 0,
      swiperList: [],
      playingActive: null,

      activeSectionId: "",
      isVisible: false, // 控制元素是否可见的响应式数据
      targetPosition: 400, // 目标元素的滚动位置

      typetabindex: 0,
    };
  },
  components: {
    pointbox,
  },
  created() {
    this.getBannerList();
    this.ObtainHotBox();
    this.GetRooms();
    // 读取本地滚动条
    const a = localStorage.getItem("scroll" || 0);
    if (a) {
      //读取本地的scroll
      setTimeout(() => {
        window.scrollTo({
          top: a,
          left: 0,
          behavior: "instant",
        });
        localStorage.setItem("scroll", 0);
      }, 300);
    }
  },

  filters: {
    GuoLvOne(val) {
      if (val.length > 14) {
        return val.substr(0, 14) + "...";
      } else {
        return val;
      }
    },
    StyChoice(val) {
      if (val == "1") {
        // return 'border-bottom-color:#e6ba1c;background-image: linear-gradient(to top, rgba(230, 186, 28,.5), transparent)'
        return (
          "background: url(" +
          require("@/assets/images/Roll/back_huang.png") +
          ") no-repeat center bottom;background-size: contain;"
        );
      } else if (val == "2") {
        return (
          "background: url(" +
          require("@/assets/images/Roll/back_fen.png") +
          ") no-repeat center bottom;background-size: contain;"
        );
      } else if (val == "3") {
        return (
          "background: url(" +
          require("@/assets/images/Roll/back_lan.png") +
          ") no-repeat center bottom;background-size: contain;"
        );
      } else if (val == "4") {
        return (
          "background: url(" +
          require("@/assets/images/Roll/back_hui.png") +
          ") no-repeat center bottom;background-size: contain;"
        );
      } else {
        return (
          "background: url(" +
          require("@/assets/images/Roll/back_hui.png") +
          ") no-repeat center bottom;background-size: contain;"
        );
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateActiveSection);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.updateActiveSection);
  },
  methods: {
    typeclick(v) {
      this.typetabindex = v;
    },
    updateActiveSection() {
      // 监听滚动事件
      if (window.pageYOffset > this.targetPosition) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }

      const sections = document.querySelectorAll(".Title");
      let closest = null;
      let minDistance = Infinity;
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        // 检查区块是否在视窗内或上方（但不超过视窗高度）
        if (rect.top >= 0 && rect.top < window.innerHeight) {
          // 选择最接近视窗顶部的区块
          if (rect.top <= minDistance) {
            minDistance = rect.top;
            closest = section.id;
          }
        }
      });
      this.activeSectionId = closest;
    },

    scrollToSection(sectionId) {
      // ... 滚动到指定区块的代码
      const element = document.getElementById(sectionId);
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
      }
      this.activeSectionId = sectionId; // 更新选中的区块ID
    },

    getBannerList() {
      Info().then((res) => {
        this.swiperList = res.data.data.banners;
      });
    },
    // 写死跳转roll房
    goroll(item) {
      if (!this.user.id) {
        this.$message.error("请先登录");
        return false;
      }
      location.href = item.href;
    },
    gotoActiveChange(item) {
      this.$router.push(item.page);
    },
    playActiveChange(index) {
      this.playingActive = index;
    },
    onChange(index) {
      this.swiperIndex = index;
    },
    onChange1(index) {
      this.$refs.swiper.swipeTo(index);
    },
    //热门宝箱
    ObtainHotBox() {
      GetHotBox().then((res) => {
        this.HotList = res.data.data;
      });
    },
    //开箱
    ToOpen(id) {
      // 记录当前滚动位置
      localStorage.setItem("scroll", document.documentElement.scrollTop);
      this.$router.push({
        path: "/OpeningBox",
        query: {
          id: id,
        },
      });
    },

    //房间
    GetRooms() {
      Rooms("1").then((res) => {
        let RoomList = res.data.data.data;
        let haveHand = RoomList.filter((item) => {
          return item.status_alias == "进行中";
        });
        this.Headlines = haveHand.slice(0, 3);
      });
    },

    //前往房间
    toWelfareInfo(id) {
      this.$router.push({
        path: "/room",
        query: {
          id: id,
        },
      });
    },

    ...mapMutations(["User", "GetRegisterShow", "GetSignInShow"]),
  },

  computed: {
    ...mapState(["user", "RegisterShow", "SignInShow"]),
  },
};
</script>
<style lang="scss" scoped>
.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.2rem 0.2rem 0 0.2rem;
  border-color: #ffffff transparent transparent transparent;
  margin: 0.2rem auto;
}

.custom-indicator {
  position: absolute;
  bottom: 0.2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  cursor: pointer;

  i {
    display: block;
    width: 0.15rem;
    height: 0.15rem;
    border-radius: 50%;
    background: #575e60;
    margin-right: 0.1rem;
  }

  .active-swiper {
    background: #d87b28;
  }
}

.playing-active {
  width: 100%;

  .playing-wrap {
    // width: 3.4rem;
    padding-top: 0.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .playing-box {
      display: flex;

      .play-item {
        position: relative;
        cursor: pointer;

        .playing-item-img {
          width: 3.4rem;
          // height:1rem;
        }

        &:nth-child(2) {
          .playing-item-img {
            width: 2.8rem;
          }
        }

        &:nth-child(3) {
          .playing-item-img {
            width: 3.2rem;
          }
        }

        &:nth-child(4) {
          .playing-item-img {
            width: 3.6rem;
          }
        }

        .play-info {
          position: absolute;
          bottom: 35%;
          left: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          transform: translateX(-35%);

          span {
            font-size: 0.2rem;
          }

          img {
            width: 1.2rem;
            max-width: 100px;
          }
        }

        .play-info:nth-of-type(4) {
          margin-top: 10px;
        }

        &:nth-child(1) {
          transform: translate(18%, 4%);

          .play-info {
            transform: translate(-20%, 80%);
          }
        }

        &:nth-child(2) {
          transform: translate(15%, 0%);

          .play-info {
            transform: translate(-60%, 0%);
          }
        }

        &:nth-child(3) {
          transform: translate(-5%, 0%);

          .play-info {
            transform: translate(-45%, 130%);
          }
        }

        &:nth-child(4) {
          .play-info {
            transform: translate(-95%, -30%);

            img {
              width: 0.7rem;
              max-width: 50px;
            }
          }

          transform: translate(-14%, 2%);
        }
      }
    }
  }
}

.swiper-item {
  position: relative;
  cursor: pointer !important;
}
.home {
  width: 14rem;
}
.home-box {
  width: 16rem;
  margin-left: -1rem;
  padding-top: 0rem;

  .Roll {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;

    .Roll_item {
      width: 4.45rem;
      height: 2.3rem;
      border-radius: 0.1rem;
      background: url(../../assets/images/Roll/itemback1.png) no-repeat center;
      background-size: 100% 100%;
      padding: 0.2rem;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        transform: translateY(-0.1rem);
        box-shadow: #e9b10e 0rem 0rem 0.05rem;
      }

      &:nth-child(2) {
        background: url(../../assets/images/Roll/itemback2.png) no-repeat center;
        background-size: 100% 100%;
      }

      &:nth-child(3) {
        background: url(../../assets/images/Roll/itemback3.png) no-repeat center;
        background-size: 100% 100%;
      }

      .Roll_left {
        width: 1.9rem;
        height: 100%;
        position: relative;

        .Roll_tit {
          font-size: 0.21rem;
          margin-top: 0.1rem;
        }

        .JG {
          display: block;
          text-align: right;
          font-size: 0.17rem;
          margin-top: 0.3rem;
          padding: 0 0.01rem;
          background: red;
          height: 0.4rem;
          line-height: 0.4rem;
          text-align: center;
          background: rgba($color: #2d384e, $alpha: 0.3);
          border-radius: 0.04rem;

          img {
            vertical-align: middle;
            width: 0.25rem;
          }
        }

        .DJS {
          position: absolute;
          left: -0.33rem;
          top: 1.25rem;
          width: 2.23rem;
          height: 0.7rem;
          background: url(../../assets/images/Roll/DJSback.png) no-repeat center;
          background-size: 100% 80%;
          box-sizing: border-box;

          .van-count-down {
            height: 100%;
            display: flex;
            padding-top: 0.17rem;
            justify-content: center;

            .colon {
              display: inline-block;
              margin: 0 4px;
              color: #fff;
            }

            .block {
              display: inline-block;
              width: 22px;
              color: #fff;
              font-size: 0.22rem;
              text-align: center;
            }
          }
        }
      }

      .Roll_right {
        width: 2rem;
        height: 100%;
        position: relative;

        .Roll_pic {
          width: 1.5rem;
          height: 1.5rem;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        p {
          position: absolute;
          right: -0.1rem;
          bottom: 0.1rem;
          width: 1.3rem;
          height: 0.4rem;
          background: #212936;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0.06rem;

          img {
            width: 0.25rem;
            height: 0.25rem;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .home_top {
    width: 100%;
    height: 2.25rem;
    display: flex;
    justify-content: space-between;

    .swiper {
      width: 4.8rem;
      height: 2.25rem;
      border-radius: 6px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        vertical-align: middle;
      }
    }

    .Headlines-list {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .Headlines-item {
        width: 3.6rem;
        height: 2.25rem;
        cursor: pointer;
        background: url(../../assets/images/public/back_pub.jpg) no-repeat;
        background-size: cover;
        border-radius: 0.1rem;
        padding: 0.1rem;
        box-sizing: border-box;
        position: relative;

        .top {
          display: flex;
          align-items: center;

          img {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            margin-right: 0.1rem;
          }

          .describe {
            .name {
              font-size: 0.16rem;
              font-weight: bold;
            }

            p {
              font-size: 0.14rem;
            }
          }
        }

        .Roll_Goods {
          width: 100%;
          height: 0.8rem;
          margin-top: 10px;
          display: flex;
          box-sizing: border-box;
          justify-content: space-around;
          flex-wrap: wrap;
          overflow: hidden;

          .Roll_Goods_item {
            width: 30%;
            height: 0.8rem;
            border-bottom: 2px solid transparent;
            box-sizing: border-box;

            .pic {
              width: 90%;
              height: 90%;
              margin: 0.02rem auto 0;
              display: flex;
              justify-content: center;
              align-items: center;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;

              img {
                max-width: 90%;
                max-width: 90%;
              }
            }
          }
        }

        .countDown {
          margin-top: -0.15rem;
          text-align: center;

          .colon {
            display: inline-block;
            width: 0.4rem;
            height: 0.3rem;
            text-align: center;
            color: #fff;
            font-size: 0.44rem;
            line-height: 1rem;
          }

          .block {
            display: inline-block;
            width: 0.6rem;
            height: 0.4rem;
            background: rgba(48, 57, 81, 0.8);
            border-top: 1px solid #49587e;
            color: #fff;
            font-size: 0.22rem;
            line-height: 0.4rem;
            letter-spacing: 0.4em;
            padding-left: 0.08rem;
            text-align: center;
            background-size: cover;
            box-sizing: border-box;
            border-radius: 0.04rem;
          }
        }
      }
    }
  }

  .home-List-box {
    // margin-top: 0.1rem;
    .Title {
      width: 19.2rem;
      // margin-top: 0.4rem;
      height: 1rem;
      margin-left: -1.6rem;
      margin-top: 0.2rem;
      // padding: 0.1rem;
      // margin-bottom: 0.4rem;

      .play-title {
        // width: 3.4rem;
        height: 1rem;
      }
    }

    .home-List {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-bottom: 0.2rem;
      .home-list-item {
        width: 20%;
        cursor: pointer;
        margin: 0rem 0 0rem;
        position: relative;
        // padding-bottom: 20px;
        .tagbox {
          position: absolute;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          img {
            width: 0.68rem;
            height: 0.21rem;
          }
        }
        .weapon_cover {
          // width: 2rem;
          height: 2.8rem;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          z-index: 99;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 50%;
            margin-left: 0.3rem;
            margin-top: -0.1rem;
            // max-width: 100%;
            // max-height: 100%;
            transition: 0.3s;
          }
        }

        .pic {
          //
          position: absolute;
          left: 50%;
          top: 50%;
          width: 90%;
          transform: translate(-45%, -57%);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9;

          img {
            max-width: 100%;
            max-height: 100%;
            transition: 0.3s;
          }
        }

        p {
          height: 20px;
          font-size: 0.18rem;
          line-height: 25px;
          color: #fff;
          text-align: center;
        }

        .Blind_btn {
          width: 100%;
          height: 0.34rem;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          z-index: 99;

          p {
            display: none;
            width: 50%;
            height: 34px;
            text-align: center;
            line-height: 34px;
            font-size: 0.16rem;
            /*font-weight: 550;*/
            color: #fff;
            position: relative;
            z-index: 99;
          }

          span {
            width: 90%;
            text-align: center;
            font-size: 0.2rem;
            color: #fff;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            img {
              margin-right: 10px;
              width: 26px;
            }
          }
        }

        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-size: 100%;
        }

        &:hover {
          &::before {
            top: -0.3rem;
            width: 3.5rem;
            height: 3.5rem;
            animation: zhuan 10s linear infinite;
            background-repeat: no-repeat;
            background-image: url("../../assets/images/OpenBox/pic-bg.png");
          }

          .weapon_cover {
            img {
              transform: rotate(-10deg) scale(1.6);
            }
          }
          .pic {
            img {
              transform: rotate(0deg) scale(1.3);
            }
          }

          .Blind_btn {
            position: relative;
            z-index: 99;

            span {
              display: block;
              color: #fff;
            }

            p {
              display: none;
              color: #000;
            }
          }
        }
      }
    }
    .home-List-5 {
      justify-content: flex-start;
    }
  }
  .typetab {
    width: 11rem;
    height: 0.84rem;
    margin: 0.15rem auto;
    display: flex;
    background: url("../../assets/images/home/typetabbg.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    .tabitem {
      position: absolute;
      bottom: 0;
      width: 2.3rem;
      height: 0.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 0.2rem;
      color: #ffffff;
    }
    .tabl {
      left: 23%;
      background: url("../../assets/images/home/tabl.png") no-repeat;
      background-size: 100% 100%;
    }
    .actabl {
      background: url("../../assets/images/home/actabl.png") no-repeat;
      background-size: 100% 100%;
    }
    .tabr {
      right: 23%;
      background: url("../../assets/images/home/tabr.png") no-repeat;
      background-size: 100% 100%;
    }
    .actabr {
      background: url("../../assets/images/home/actabr.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
.uav {
  position: fixed;
  left: 0.4rem;
  top: 30%;
  #toc {
    width: 1.5rem;
    font-weight: 400;
    text-align: center;
    font-size: 0.18rem;
    color: #9373c3;
    background: url("../../assets/images/home/maodianbg.png") no-repeat;
    background-size: 100% 100%;
    padding: 0.3rem 0;
    .tocbox {
      width: 1.16rem;
      text-align: center;
      margin: 0 auto 0.25rem;
    }
    .active {
      /* 选中状态的样式 */
      color: #dbc4ff;
      background: url("../../assets/images/home/acmaodian.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
.hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s linear;
}

.visible {
  visibility: visible;
  opacity: 1;
}

@keyframes zhuan {
  to {
    transform: rotate(1turn);
  }
}
</style>
